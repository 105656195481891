import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const handleApiError = (error) => {
  if (axios.isAxiosError(error)) {
    // Handle specific HTTP status codes if needed

    // NOTE: This syntax has some parsing problem
    // if (error.response?.status === 404) {
    //   console.log('Not Found Error:', error.message)
    // }

    if (error.response && error.response.status === 400) {
      console.log('Bad request:', error.message)
    }

    if (error.response && error.response.status === 401) {
      console.log('Auth error:', error.message)
    }

    if (error.response && error.response.status === 404) {
      console.log('Not found error:', error.message)
    }

    return {
      success: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : 'Internal server error'
    }
  } else {
    // Handle other errors (e.g., network errors)
    return { success: false, message: 'Internal server error' }
  }
}

const toolsInfoService = {
  getToolsInfo: async (filter) => {
    try {
      const response = await axiosObj.get('/tools-info', {
        params: {
          ...filter
        }
      })

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  updateToolsInfo: async (machine, data) => {
    try {
      const response = await axiosObj.patch(`/tools-info/${machine}`, data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  }
}

export default toolsInfoService
