import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledModal from '../../components/StyledModal/Modal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

import { MACHINES } from '../../utils/constant.utils'
import {
  IProductionOrder,
  ProductionOrderFormFields
} from '../../types/productionOrder'
import { OptionItem } from '../../types/common'

import partService from '../../service/part.service'
import { IPart } from '../../types/parts'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  selectedProductionOrder: IProductionOrder | null
  addProductionOrder?: (partData: ProductionOrderFormFields) => void
  editProductionOrder?: (
    workOrderNum: number,
    newData: ProductionOrderFormFields
  ) => void
  isEditMode?: boolean
  isLoading?: boolean
}

const AddProductionOrderModal = (props: Props) => {
  let defaultProductionOderNum: string | number = ''
  let defaultProductionOderQuantity: string | number = ''

  let defaultSelectedPart: {
    partName: string
    partType: string
  } | null = null

  let defaultMachine: OptionItem = {
    value: 0,
    label: 'Select machine'
  }

  if (props.isEditMode && props.selectedProductionOrder) {
    defaultProductionOderNum = props.selectedProductionOrder.workOrderNum
    defaultProductionOderQuantity = props.selectedProductionOrder.quantity

    defaultSelectedPart = {
      partName: props.selectedProductionOrder.partName,
      partType: props.selectedProductionOrder.partType
    }

    defaultMachine.value = props.selectedProductionOrder.machine
    defaultMachine.label = props.selectedProductionOrder.machine.toUpperCase()
  }

  const [partNameOptions, setPartNameOptions] = useState<IPart[]>([])
  const [productionOrderNum, setProductionOrderNum] = useState(
    defaultProductionOderNum
  )
  const [productionOrderQuantity, setProductionOrderQuantity] = useState(
    defaultProductionOderQuantity
  )
  const [selectedPartData, setSelectedPartData] = useState(defaultSelectedPart)
  const [machine, setMachine] = useState(defaultMachine)

  useEffect(() => {
    if (!machine.value) return

    const fetchParts = async () => {
      const response = await partService.getParts({
        machine: machine.value
      })
      if (response.success) {
        setPartNameOptions(response.data)
      }
    }
    fetchParts()
  }, [machine])

  return (
    <StyledModal
      width={500}
      loading={props.isLoading}
      isOpen={props.isModalOpen}
      title={
        props.isEditMode ? 'Edit production order' : 'Add production order'
      }
      closeHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label="PO number"
          placeholderText="Enter PO number"
          textValue={productionOrderNum}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            setProductionOrderNum(e.target.value)
          }}
          isDisabled={false}
          type="string"
        />
        <StyledTextFieldWithLabel
          label="PO quantity"
          placeholderText="Enter PO quantity"
          textValue={productionOrderQuantity}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value
            if (parseInt(inputValue) < 0) {
              // If it's below zero, set the value to an empty string
              setProductionOrderQuantity('')
            } else {
              // Otherwise, update the state with the new value
              setProductionOrderQuantity(parseInt(inputValue))
            }
          }}
          isDisabled={false}
          type="number"
        />
        <StyledSelectField
          label="Machine"
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={MACHINES}
          onChangeHandler={(value: OptionItem) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        <StyledSelectField
          label="Part number"
          placeHolderText="Select part number"
          selectedValue={
            selectedPartData
              ? {
                  value: selectedPartData.partName,
                  label: selectedPartData.partName
                }
              : {
                  value: 0,
                  label: 'Select part number'
                }
          }
          selectOptions={partNameOptions.map((item) => ({
            value: item.type,
            label: item.type
          }))}
          onChangeHandler={(value: OptionItem) => {
            const data = partNameOptions.find(
              (item) => item.type === value.value
            )
            if (data) {
              setSelectedPartData({
                partName: data.type,
                partType: data.name
              })
            }
          }}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No part number found"
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() =>
            props.isEditMode && props.selectedProductionOrder
              ? selectedPartData &&
                props.editProductionOrder &&
                props.editProductionOrder(
                  props.selectedProductionOrder.workOrderNum,
                  {
                    workOrderNum: parseInt(
                      String(productionOrderNum).replace(/_/g, '')
                    ),
                    quantity: productionOrderQuantity as number,
                    partName: selectedPartData.partName,
                    partType: selectedPartData.partType,
                    machine: machine.value
                  }
                )
              : selectedPartData &&
                props.addProductionOrder &&
                props.addProductionOrder({
                  workOrderNum: parseInt(
                    String(productionOrderNum).replace(/_/g, '')
                  ),
                  quantity: productionOrderQuantity as number,
                  partName: selectedPartData.partName,
                  partType: selectedPartData.partType,
                  machine: machine.value
                })
          }
        >
          {props.isEditMode ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default AddProductionOrderModal
