import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import ToolType from './ToolType'
import ToolManagement from './ToolLife'
import MachinesToolInfo from './MachinesToolInfo'

const toolLifePageTab = {
  TOOL: { name: 'Tool', value: 'tool' },
  TOOL_TYPE: { name: 'Tool type', value: 'tool-type' },
  MACHINES_TOOL_INFO: {
    name: 'Machines tool info',
    value: 'machines-tool-info'
  }
}

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 24px 0 0',
  borderBottom: `1px solid #eeeeee`
}))

const Tab = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px 20px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const ToolUpdate = () => {
  //default values
  let defaultTab = toolLifePageTab.TOOL.value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')
  if (tab) {
    defaultTab = tab
  }
  //component state
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)

  useEffect(() => {
    navigate(`?tab=${activeSubTab}`)
  }, [activeSubTab])

  const handleSubTabChange = (tab: string) => {
    setActiveSubTab(tab)
  }

  return (
    <StyledPageContainer>
      <TabsContainer>
        {React.Children.toArray(
          Object.values(toolLifePageTab).map((tab) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 49.6px)',
          overflowY: 'auto'
        }}
      >
        {activeSubTab === toolLifePageTab.TOOL.value && <ToolManagement />}
        {activeSubTab === toolLifePageTab.TOOL_TYPE.value && <ToolType />}
        {activeSubTab === toolLifePageTab.MACHINES_TOOL_INFO.value && (
          <MachinesToolInfo />
        )}
      </Box>
    </StyledPageContainer>
  )
}
export default ToolUpdate
