import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

import toolService from '../../service/toolLife.service'

const UpdateToolIdModal = (props) => {
  const [toolId, setToolId] = useState({
    value: props.toolId,
    label: props.toolId
  })
  //const [toolIdOptions, setToolIdOption] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isActive, setIsActive] = useState(props.isActive)
  const [confirmText, setConfirmText] = useState('')
  const [tools, setTools] = useState([])

  const fetchTools = async () => {
    setIsLoading(true)
    const toolsResponse = await toolService.getTools()

    if (toolsResponse.success) {
      setTools(toolsResponse.data)
      // const toolIdNoOptionsObj = {}
      // toolsResponse.data.forEach((tool, i) => {
      //   if (!toolIdNoOptionsObj[tool.toolId]) {
      //     toolIdNoOptionsObj[tool.toolId] = {
      //       label: tool.toolId,
      //       value: tool.toolId
      //     }
      //   }
      // })
      //setToolIdOption(Object.values(toolIdNoOptionsObj))
    } else {
      //TODO: set error or show error
    }

    setIsLoading(false)
  }

  React.useEffect(() => {
    fetchTools()
  }, [])

  return (
    <StyledModal
      modalWidth={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={`Change tool ${props.toolPosition} for ${props.machine}`}
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%', paddingY: '8px' }}
      >
        <StyledSelectField
          label="Tool type"
          placeholderText="Enter tool type"
          selectedValue={{
            value: props.toolType,
            label: props.toolType
          }}
          selectOptions={[]}
          onChangeHandler={() => {}}
          isMulti={false}
          isSearchable={true}
          isDisabled={true}
        />
        <StyledSelectField
          label="Tool ID"
          placeholderText="Enter tool ID"
          selectedValue={toolId}
          selectOptions={tools
            .filter((tool) => tool.toolType === props.toolType)
            .map((tool) => ({
              label: tool.toolId,
              value: tool.toolId
            }))}
          onChangeHandler={(value) => setToolId(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No tool Id found"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#000' }}>Is tool active</Typography>
          <Checkbox
            sx={{ padding: 0 }}
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </Box>
        <StyledTextFieldWithLabel
          label="Confirm"
          placeholderText="Type confirm"
          textValue={confirmText}
          onChangeHandler={(e) => {
            setConfirmText(e.target.value)
          }}
          isDisabled={false}
          type="text"
          isMargin={true}
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          disabled={isLoading || confirmText !== 'confirm'}
          variant="contained"
          sx={{
            color: '#fff',
            '&:disabled': {
              cursor: 'not-allowed'
            }
          }}
          onClick={() => {
            props.submitHandler(
              props.machine,
              props.toolPosition,
              props.toolType,
              toolId.value,
              isActive
            )
          }}
        >
          Update
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default UpdateToolIdModal
