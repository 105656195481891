import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import Checkbox from '@mui/material/Checkbox'

//services
import toolInfoService from '../../service/toolsInfo.service'
import UpdateToolIdModal from './UpdateToolIdModal'
import toolsInfoService from '../../service/toolsInfo.service'

const KPITable = styled('table')(() => ({
  borderCollapse: 'collapse',
  width: '100%'
}))

const KPITableRow = styled('tr')(() => ({
  width: '100%',
  '&:nth-of-type(even)': {}
}))

const KPITableHeading = styled('th')(() => ({
  border: '1px solid #dddddd',
  textAlign: 'left',
  padding: '8px'
}))

const KPITableEntry = styled('td')(({ alignment }) => ({
  border: '1px solid #dddddd',
  textAlign: alignment ? alignment : 'left',
  padding: '8px'
}))

const MachinesToolInfo = () => {
  const [refetchCount, setRefetchCount] = useState(0)
  const [toolsInfo, setToolsInfo] = useState([])
  const [toolIdUpdateModalInfo, setToolIdUpdateModalInfo] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchToolsInfo = async () => {
      try {
        setLoading(true)
        const toolsInfo = await toolInfoService.getToolsInfo()
        console.log('toolInfoService', toolsInfo)
        setToolsInfo(toolsInfo.data)
      } catch (error) {
        console.log('error', error)
      } finally {
        setLoading(false)
      }
    }
    fetchToolsInfo()
  }, [refetchCount])

  return (
    <Box
      sx={{ padding: '16px', display: 'flex', flexWrap: 'wrap', gap: '16px' }}
    >
      {loading && <Box>Loading...</Box>}
      {!loading &&
        toolsInfo.map((rowItem) => (
          <Box
            sx={{
              border: '1px solid grey',
              borderRadius: '8px',
              padding: '16px',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}
            key={rowItem.id}
          >
            <Box>Machine: {rowItem.machine}</Box>
            <Box>
              <KPITable>
                <KPITableRow>
                  <KPITableHeading>Tool</KPITableHeading>
                  <KPITableHeading>Active</KPITableHeading>
                  <KPITableHeading>Tool type</KPITableHeading>
                  <KPITableHeading>Tool ID</KPITableHeading>
                  <KPITableHeading>Tool life</KPITableHeading>
                  <KPITableHeading>Used life</KPITableHeading>
                  <KPITableHeading>Remaining life</KPITableHeading>
                  <KPITableHeading>Changed at</KPITableHeading>
                  <KPITableHeading>Action</KPITableHeading>
                </KPITableRow>
                {rowItem.toolsInfo.map((toolItem, index) => (
                  <KPITableRow key={index}>
                    <KPITableEntry>Tool {toolItem.toolPosition}</KPITableEntry>
                    <KPITableEntry alignment="center">
                      <Checkbox
                        disabled={true}
                        sx={{ padding: 0 }}
                        checked={toolItem.isActive}
                      />
                    </KPITableEntry>
                    <KPITableEntry>{toolItem.toolType}</KPITableEntry>
                    <KPITableEntry>{toolItem.toolId}</KPITableEntry>
                    <KPITableEntry>{toolItem.toolLife}</KPITableEntry>
                    <KPITableEntry>
                      {toolItem.isActive ? toolItem.usedLife : 'N/A'}
                    </KPITableEntry>
                    <KPITableEntry>
                      {toolItem.isActive
                        ? toolItem.toolLife - toolItem.usedLife
                        : 'N/A'}
                    </KPITableEntry>
                    <KPITableEntry>{toolItem.changedAt}</KPITableEntry>
                    <KPITableEntry alignment="center">
                      <BuildCircleOutlinedIcon
                        onClick={() => {
                          setToolIdUpdateModalInfo({
                            toolPosition: toolItem.toolPosition,
                            isActive: toolItem.isActive,
                            toolType: toolItem.toolType,
                            toolId: toolItem.toolId,
                            machine: rowItem.machine
                          })
                        }}
                        fontSize="small"
                      />
                    </KPITableEntry>
                  </KPITableRow>
                ))}
              </KPITable>
            </Box>
          </Box>
        ))}
      {toolIdUpdateModalInfo && (
        <UpdateToolIdModal
          isModalOpen={toolIdUpdateModalInfo}
          machine={toolIdUpdateModalInfo.machine}
          toolPosition={toolIdUpdateModalInfo.toolPosition}
          isActive={toolIdUpdateModalInfo.isActive}
          toolId={toolIdUpdateModalInfo.toolId}
          toolType={toolIdUpdateModalInfo.toolType}
          closeHandler={() => setToolIdUpdateModalInfo(null)}
          submitHandler={async (
            machine,
            toolPosition,
            toolType,
            toolId,
            isActive
          ) => {
            console.log(
              'args',
              machine,
              toolPosition,
              toolType,
              toolId,
              isActive
            )
            try {
              await toolsInfoService.updateToolsInfo(machine, {
                toolPosition,
                toolType,
                toolId,
                isActive
              })
              setToolIdUpdateModalInfo(null)
              setRefetchCount((prev) => prev + 1)
            } catch (error) {
              console.log('error', error)
            }
          }}
        />
      )}
    </Box>
  )
}

export default MachinesToolInfo
