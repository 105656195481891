import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import LaunchIcon from '@mui/icons-material/Launch'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'

//services
import productionOrderService from '../../service/productionOrder.service'
import operationTypeService from '../../service/operationType.service'
import partService from '../../service/part.service'

//types
import { OptionItem } from '../../types/common'
import {
  IProductionOrderApiNew,
  ProductionOrderFilter,
  ProductionOrderFormFieldsNew
} from '../../types/productionOrder'

import NewProductionOrderModal from './NewProductionOrderModal'
import ProductionOrderPreviewModal from './ProductionOrderPreviewModal'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
  columnGap: '16px'
}))

const machineOptions = MACHINES.map((machine) => ({
  value: machine.value,
  label: machine.label
}))

const NewProductionOrder = () => {
  const defaultProductionOrder = {
    value: 'all',
    label: 'All PO numbers'
  }

  const defaultMachine = {
    value: 'all',
    label: 'All machines'
  }

  const defaultPartType = {
    value: 'all',
    label: 'All part family'
  }

  const defaultPartName = {
    value: 'all',
    label: 'All part numbers'
  }

  const [productionOrders, setProductionOrders] = React.useState<Record<
    number,
    IProductionOrderApiNew
  > | null>(null)

  const [partNameOptions, setPartNameOptions] = React.useState<OptionItem[]>([])
  const [partTypeOptions, setPartTypeOptions] = React.useState<OptionItem[]>([])
  const [productionOrderOptions, setProductionOrderOptions] = React.useState<
    OptionItem[]
  >([])

  const [selectedProductionOrder, setSelectedProductionOrder] =
    React.useState<OptionItem>(defaultProductionOrder)
  const [selectedPartName, setSelectedPartName] =
    React.useState<OptionItem>(defaultPartName)
  const [selectedPartType, setSelectedPartType] =
    React.useState<OptionItem>(defaultPartType)
  const [selectedMachine, setSelectedMachine] =
    React.useState<OptionItem>(defaultMachine)

  const [
    selectedProductionOrderForAction,
    setSelectedProductionOrderForAction
  ] = React.useState<
    (IProductionOrderApiNew & { mode: 'edit' | 'delete' | 'preview' }) | null
  >(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [refetchCount, setRefetchCount] = React.useState(0)
  const [openAddPartModal, setOpenAddPartModal] = React.useState(false)

  React.useEffect(() => {
    const fetchProductionOrders = async () => {
      setIsLoading(true)

      const promises = [
        productionOrderService.getProductionOrdersNew(),
        operationTypeService.getOperationTypes(),
        partService.getParts({})
      ]

      setIsLoading(true)
      const data = await Promise.allSettled(promises)

      const productionOrderResponse = data[0] as any
      const operationTypeResponse = data[1] as any
      const partResponse = data[2] as any

      if (
        productionOrderResponse.status === 'fulfilled' &&
        productionOrderResponse.value.success
      ) {
        const productionOrderOptionsObj: Record<number, OptionItem> = {}
        const productionOrdersData: Record<number, IProductionOrderApiNew> = {}

        productionOrderResponse.value.data.forEach(
          (productionOrder: any, i: number) => {
            if (!productionOrderOptionsObj[productionOrder.workOrderNum]) {
              productionOrderOptionsObj[productionOrder.workOrderNum] = {
                label: productionOrder.workOrderNum,
                value: productionOrder.workOrderNum
              }
            }

            if (!productionOrdersData[productionOrder.workOrderNum]) {
              productionOrdersData[productionOrder.workOrderNum] = {
                ...productionOrder
              }
            }
          }
        )
        setProductionOrders(productionOrdersData)
        setProductionOrderOptions(Object.values(productionOrderOptionsObj))
      }

      if (
        operationTypeResponse.status === 'fulfilled' &&
        operationTypeResponse.value.success
      ) {
        const partTypeOptions = operationTypeResponse.value.data.map(
          (item: any) => ({
            value: item.type,
            label: item.type
          })
        )

        setPartTypeOptions(partTypeOptions)
      }

      if (partResponse.status === 'fulfilled' && partResponse.value.success) {
        const partNameOptions = partResponse.value.data.map((item: any) => ({
          value: item.id,
          label: item.type
        }))

        setPartNameOptions(partNameOptions)
      }

      setIsLoading(false)
    }
    fetchProductionOrders()
  }, [refetchCount])

  const addProductionOrder = async (
    productionOrderData: ProductionOrderFormFieldsNew
  ) => {
    console.log(productionOrderData)
    if (
      !productionOrderData.workOrderNum ||
      productionOrderData.workOrderNum === 0
    ) {
      showNotification('PO number is required', 'error')
      return
    }

    if (!productionOrderData.quantity || productionOrderData.quantity === 0) {
      showNotification('production quantity is required', 'error')
      return
    }

    if (!productionOrderData.partId) {
      showNotification('Part is required', 'error')
      return
    }
    setIsLoading(true)
    const partsResponse =
      await productionOrderService.addProductionOrderNew(productionOrderData)
    if (partsResponse.success) {
      showNotification(`Production order added successfully`, 'success')
    } else {
      showNotification(partsResponse.message, 'error')
    }
    setOpenAddPartModal(false)
    setRefetchCount((prev) => prev + 1)
    setIsLoading(false)
  }

  const editProductionOrder = async (
    productionOrderData: ProductionOrderFormFieldsNew
  ) => {
    if (
      !productionOrderData.workOrderNum ||
      productionOrderData.workOrderNum === 0
    ) {
      showNotification('PO number is required', 'error')
      return
    }

    if (!productionOrderData.quantity || productionOrderData.quantity === 0) {
      showNotification('production quantity is required', 'error')
      return
    }

    if (!productionOrderData.partId) {
      showNotification('Part is required', 'error')
      return
    }

    setIsLoading(true)
    const partsResponse = await productionOrderService.editProductionOrderNew(
      productionOrderData.workOrderNum,
      productionOrderData
    )
    if (partsResponse.success) {
      showNotification(`Production order updated successfully`, 'success')
    } else {
      showNotification(partsResponse.message, 'error')
    }

    setRefetchCount((prev) => prev + 1)
    setOpenAddPartModal(false)
    setIsLoading(false)
  }

  const deletePart = async (workOrderNum: number) => {
    if (!workOrderNum) {
      showNotification('part id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const response =
        await productionOrderService.deleteProductionOrder(workOrderNum)
      if (response.success) {
        showNotification(`Production order deleted successfully`, 'success')
      } else {
        showNotification(response.message, 'error')
      }
      setRefetchCount((prev) => prev + 1)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const clearAll = () => {
    setSelectedMachine(defaultMachine)
    setSelectedProductionOrder(defaultProductionOrder)
    setSelectedPartName(defaultPartName)
    setSelectedPartType(defaultPartType)
  }

  //1. Create search object
  const searchObj: ProductionOrderFilter = {
    ...(selectedProductionOrder &&
      selectedProductionOrder.value !== 'all' && {
        workOrderNum: selectedProductionOrder.value
      }),
    ...(selectedPartName &&
      selectedPartName.value !== 'all' && {
        partId: selectedPartName.value
      })
  }
  //console.log({ searchObj })

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj) as (keyof typeof searchObj)[]

  //3. filter
  const filteredRows = React.useMemo(() => {
    let filteredRows = productionOrders ? Object.values(productionOrders) : []
    if (productionOrders && keysToChecked.length > 0) {
      filteredRows = Object.values(productionOrders).filter(
        (productionOrderRow) => {
          return keysToChecked.every(
            (key) => searchObj[key] === productionOrderRow[key]
          )
        }
      )
    }

    return filteredRows
  }, [productionOrders, selectedMachine, selectedPartName, selectedPartType])

  const productionOrderColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'PO number',
      accessor: 'workOrderNum'
    },
    {
      Header: 'PO quantity',
      accessor: 'quantity'
    },
    {
      Header: 'Part family',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { original: IProductionOrderApiNew } }
      }) => {
        return <>{row.original.part.name}</>
      }
    },
    {
      Header: 'Part number',
      accessor: 'partId',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { original: IProductionOrderApiNew } }
      }) => {
        return <>{row.original.part.type}</>
      }
    },
    {
      Header: 'Machine',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { original: IProductionOrderApiNew } }
      }) => {
        return <>{row.original.part.machine}</>
      }
    },
    {
      Header: 'Tools Info',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { original: IProductionOrderApiNew } }
      }) => {
        return (
          <LaunchIcon
            onClick={() => {
              setSelectedProductionOrderForAction({
                mode: 'preview',
                ...row.original
              })
            }}
            sx={{ cursor: 'pointer' }}
            fontSize="small"
          />
        )
      }
    },
    {
      Header: 'Action',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { original: IProductionOrderApiNew } }
      }) => {
        return (
          <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
            {/*    <EditOutlinedIcon
              sx={{ cursor: 'pointer' }}
              fontSize="small"
              onClick={() =>
                setSelectedProductionOrderForAction({
                  mode: 'edit',
                  ...row.original
                })
              }
            />
           <DeleteOutlineOutlinedIcon
              sx={{ cursor: 'pointer' }}
              fontSize="small"
              onClick={() =>
                setSelectedProductionOrderForAction({
                  mode: 'delete',
                  ...row.original
                })
              }
            /> */}
          </Box>
        )
      }
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <FilterContainer>
          <Button
            variant="contained"
            sx={{
              color: '#fff',
              textTransform: 'none'
            }}
            onClick={() => {
              setOpenAddPartModal(true)
            }}
          >
            Add production order
          </Button>

          <Button
            startIcon={<FilterAltOffIcon />}
            variant="outlined"
            sx={{
              marginLeft: 'auto',
              textTransform: 'none',
              color: '#000'
            }}
            onClick={clearAll}
            disabled={keysToChecked.length === 0}
          >
            Clear
          </Button>
        </FilterContainer>
        <Stack
          spacing={2}
          direction="row"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          <StyledSelectField
            placeHolderText="Select machine"
            selectedValue={selectedMachine}
            selectOptions={[defaultMachine, ...machineOptions]}
            onChangeHandler={(value: OptionItem) => setSelectedMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
          <StyledSelectField
            placeHolderText="Select PO number"
            selectedValue={selectedProductionOrder}
            selectOptions={[defaultProductionOrder, ...productionOrderOptions]}
            onChangeHandler={(value: OptionItem) =>
              setSelectedProductionOrder(value)
            }
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No PO found"
          />
          <StyledSelectField
            placeHolderText="Select part family"
            selectedValue={selectedPartType}
            selectOptions={[defaultPartType, ...partTypeOptions]}
            onChangeHandler={(value: OptionItem) => setSelectedPartType(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No part family found"
          />
          <StyledSelectField
            placeHolderText="Select part number"
            selectedValue={selectedPartName}
            selectOptions={[defaultPartName, ...partNameOptions]}
            onChangeHandler={(value: OptionItem) => setSelectedPartName(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No part number found"
          />
        </Stack>
      </Stack>
      <Box sx={{ padding: '16px' }}>
        <StyledTableContainer>
          {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
          {!isLoading && filteredRows.length === 0 && (
            <StyledEmptyHeader>
              There are no production orders
            </StyledEmptyHeader>
          )}
          {!isLoading && filteredRows.length > 0 && (
            <StyledTable
              pagination={false}
              columns={productionOrderColumns}
              data={filteredRows}
              hiddenColumns={['id', 'index']}
            />
          )}
        </StyledTableContainer>
      </Box>
      {openAddPartModal && (
        <NewProductionOrderModal
          isModalOpen={openAddPartModal}
          primaryActionHandler={addProductionOrder}
          closeHandler={() => setOpenAddPartModal(false)}
        />
      )}
      {selectedProductionOrderForAction &&
        selectedProductionOrderForAction.mode === 'edit' && (
          <NewProductionOrderModal
            isModalOpen={selectedProductionOrderForAction.mode === 'edit'}
            defaultData={selectedProductionOrderForAction}
            primaryActionHandler={editProductionOrder}
            closeHandler={() => setSelectedProductionOrderForAction(null)}
          />
        )}
      {selectedProductionOrderForAction &&
        selectedProductionOrderForAction.mode === 'preview' && (
          <ProductionOrderPreviewModal
            isModalOpen={selectedProductionOrderForAction.mode === 'preview'}
            defaultData={selectedProductionOrderForAction}
            closeHandler={() => setSelectedProductionOrderForAction(null)}
          />
        )}
      {selectedProductionOrderForAction && (
        <ConfirmationModal
          isModalOpen={selectedProductionOrderForAction.mode === 'delete'}
          description={`Do you want to delete the following production order '${selectedProductionOrderForAction.workOrderNum}' ?`}
          buttonTitle="Delete"
          clickHandler={() =>
            deletePart(selectedProductionOrderForAction.workOrderNum)
          }
          closeHandler={() => setSelectedProductionOrderForAction(null)}
        />
      )}
    </>
  )
}

export default NewProductionOrder
