import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import AddProductionOrderModal from './AddProductionOrderModal'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'

//services
import productionOrderService from '../../service/productionOrder.service'

//types
import { OptionItem } from '../../types/common'
import {
  IProductionOrder,
  ProductionOrderFilter,
  ProductionOrderFormFields
} from '../../types/productionOrder'
import { getDisplayName } from '../../utils/common.utils'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
  columnGap: '16px'
}))

const machineOptions = MACHINES.map((machine) => ({
  value: machine.value,
  label: machine.label
}))

const ActionBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
      {children}
    </div>
  )
}

const ProductionOrder = () => {
  const defaultProductionOrderNum = {
    value: 'all',
    label: 'All PO numbers'
  }

  const defaultMachine = {
    value: 'all',
    label: 'All machines'
  }

  const defaultOperation = {
    value: 'all',
    label: 'All part family'
  }

  const defaultPartName = {
    value: 'all',
    label: 'All part numbers'
  }

  const [productionOrders, setProductionOrders] = React.useState<Record<
    number,
    IProductionOrder
  > | null>(null)
  const [partNameOptions, setPartNameOptions] = React.useState<OptionItem[]>([])
  const [partTypeOptions, setPartTypeOptions] = React.useState<OptionItem[]>([])
  const [productionOrderNumbers, setProductionOrderNumbers] = React.useState<
    OptionItem[]
  >([])

  const [productionOrderNum, setProductionOrderNum] =
    React.useState<OptionItem>(defaultProductionOrderNum)
  const [partName, setPartName] = React.useState<OptionItem>(defaultPartName)
  const [partType, setPartType] = React.useState<OptionItem>(defaultOperation)
  const [machine, setMachine] = React.useState<OptionItem>(defaultMachine)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [openAddPartModal, setOpenAddPartModal] = React.useState(false)
  const [selectedProductionOrder, setSelectedProductionOrder] =
    React.useState<IProductionOrder | null>(null)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

  const fetchProductionOrders = async () => {
    const data = {}

    setIsLoading(true)
    const response = await productionOrderService.getProductionOrders(data)

    if (response.success) {
      const productionOrdersData: Record<number, IProductionOrder> = {}
      const partNameOptionsObj: Record<string, OptionItem> = {}
      const partTypeOptionsObj: Record<string, OptionItem> = {}
      const productionOrderOptionsObj: Record<string, OptionItem> = {}

      response.data.forEach((productionOrder, i) => {
        if (!partNameOptionsObj[productionOrder.partName]) {
          partNameOptionsObj[productionOrder.partName] = {
            label: productionOrder.partName,
            value: productionOrder.partName
          }
        }

        if (!partTypeOptionsObj[productionOrder.partType]) {
          partTypeOptionsObj[productionOrder.partType] = {
            label: productionOrder.partType,
            value: productionOrder.partType
          }
        }

        if (!productionOrderOptionsObj[productionOrder.workOrderNum]) {
          productionOrderOptionsObj[productionOrder.workOrderNum] = {
            label: productionOrder.workOrderNum,
            value: productionOrder.workOrderNum
          }
        }
        if (!partTypeOptionsObj[productionOrder.partType]) {
          partTypeOptionsObj[productionOrder.partType] = {
            label: productionOrder.partType,
            value: productionOrder.partType
          }
        }

        if (!productionOrdersData[productionOrder.workOrderNum]) {
          productionOrdersData[productionOrder.workOrderNum] = {
            ...productionOrder
          }
        }
      })

      setPartNameOptions(Object.values(partNameOptionsObj))
      setPartTypeOptions(Object.values(partTypeOptionsObj))
      setProductionOrderNumbers(Object.values(productionOrderOptionsObj))
      setProductionOrders(productionOrdersData)
    } else {
      //TODO: set error or show error
      setProductionOrders(null)
    }

    setIsLoading(false)
  }

  React.useEffect(() => {
    fetchProductionOrders()
  }, [])

  const addProductionOrder = async (
    productionOrderData: ProductionOrderFormFields
  ) => {
    if (
      !productionOrderData.workOrderNum ||
      productionOrderData.workOrderNum === 0
    ) {
      showNotification('PO number is required', 'error')
      return
    }

    if (!productionOrderData.quantity || productionOrderData.quantity === 0) {
      showNotification('production quantity is required', 'error')
      return
    }

    if (!productionOrderData.partType) {
      showNotification('Part family is required', 'error')
      return
    }

    if (!productionOrderData.partName) {
      showNotification('Part number is required', 'error')
      return
    }

    if (!productionOrderData.machine || productionOrderData.machine === '') {
      showNotification('Machine is required', 'error')
      return
    }

    setIsLoading(true)
    const partsResponse =
      await productionOrderService.addProductionOrder(productionOrderData)
    if (partsResponse.success) {
      showNotification(`Production order added successfully`, 'success')
    } else {
      showNotification(partsResponse.message, 'error')
    }
    setOpenAddPartModal(false)
    fetchProductionOrders()
    setIsLoading(false)
  }

  const editProductionOrder = async (
    workOrderNum: number,
    productionOrderData: ProductionOrderFormFields
  ) => {
    if (
      !productionOrderData.workOrderNum ||
      productionOrderData.workOrderNum === 0
    ) {
      showNotification('PO number is required', 'error')
      return
    }

    if (!productionOrderData.quantity || productionOrderData.quantity === 0) {
      showNotification('production quantity is required', 'error')
      return
    }

    if (!productionOrderData.partType) {
      showNotification('Part family is required', 'error')
      return
    }

    if (!productionOrderData.partName) {
      showNotification('Part number is required', 'error')
      return
    }

    if (!productionOrderData.machine || productionOrderData.machine === '') {
      showNotification('Machine is required', 'error')
      return
    }

    setIsLoading(true)
    const partsResponse = await productionOrderService.editProductionOrder(
      workOrderNum,
      productionOrderData
    )
    if (partsResponse.success) {
      showNotification(`Production order updated successfully`, 'success')
    } else {
      showNotification(partsResponse.message, 'error')
    }

    fetchProductionOrders()
    setIsEditMode(false)
    setOpenAddPartModal(false)
    setIsLoading(false)
  }

  const deletePart = async (workOrderNum: number) => {
    if (!workOrderNum) {
      showNotification('part id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const response =
        await productionOrderService.deleteProductionOrder(workOrderNum)
      if (response.success) {
        showNotification(`Production order deleted successfully`, 'success')
      } else {
        showNotification(response.message, 'error')
      }
      fetchProductionOrders()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const clearAll = () => {
    setMachine(defaultMachine)
    setProductionOrderNum(defaultProductionOrderNum)
    setPartName(defaultPartName)
    setPartType(defaultOperation)
  }

  //1. Create search object
  const searchObj: ProductionOrderFilter = {
    ...(productionOrderNum &&
      productionOrderNum.value !== 'all' && {
        workOrderNum: productionOrderNum.value
      }),
    ...(machine &&
      machine.value !== 'all' && {
        machine: machine.value
      }),
    ...(partName &&
      partName.value !== 'all' && {
        partName: partName.value
      }),
    ...(partType &&
      partType.value !== 'all' && {
        partType: partType.value
      })
  }
  //console.log({ searchObj })

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj) as (keyof typeof searchObj)[]

  //3. filter
  const filteredRows = React.useMemo(() => {
    let filteredRows = productionOrders ? Object.values(productionOrders) : []
    if (productionOrders && keysToChecked.length > 0) {
      filteredRows = Object.values(productionOrders).filter(
        (productionOrderRow) => {
          return keysToChecked.every(
            (key) => searchObj[key] === productionOrderRow[key]
          )
        }
      )
    }

    return filteredRows
  }, [productionOrders, machine, partName, partType])

  const productionOrderColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'PO number',
      accessor: 'workOrderNum'
    },
    {
      Header: 'PO quantity',
      accessor: 'quantity'
    },
    {
      Header: 'Part family',
      accessor: 'partType'
    },
    {
      Header: 'Part number',
      accessor: 'partName'
    },
    {
      Header: 'Machine',
      accessor: 'machine',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { values: IProductionOrder } }
      }) => {
        return getDisplayName(row.values.machine, MACHINES)
      }
    },
    // {
    //   Header: 'Action',
    //   Cell: ({
    //     cell: { row }
    //   }: {
    //     cell: { row: { values: IProductionOrder } }
    //   }) => {
    //     return (
    //       <ActionBox>
    //         <EditIcon
    //           onClickHandler={() => {
    //             setIsEditMode(true)
    //             setSelectedProductionOrder({ ...row.values })
    //             setOpenAddPartModal(true)
    //           }}
    //         />
    //         {/* <DeleteIcon
    //           onClickHandler={() => {
    //             setOpenDeleteModal(true)
    //             setSelectedPart({ ...row.values })
    //           }}
    //         /> */}
    //       </ActionBox>
    //     )
    //   }
    // }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <FilterContainer>
          {/* <Button
            variant="contained"
            sx={{
              color: '#fff',
              textTransform: 'none'
            }}
            onClick={() => {
              setIsEditMode(false)
              setSelectedProductionOrder(null)
              setOpenAddPartModal(true)
            }}
          >
            Add production order
          </Button> */}

          <Button
            startIcon={<FilterAltOffIcon />}
            variant="outlined"
            sx={{
              marginLeft: 'auto',
              textTransform: 'none',
              color: '#000'
            }}
            onClick={clearAll}
            disabled={keysToChecked.length === 0}
          >
            Clear
          </Button>
        </FilterContainer>
        <Stack
          spacing={2}
          direction="row"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={machine}
            selectOptions={[defaultMachine, ...machineOptions]}
            onChangeHandler={(value: OptionItem) => setMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select PO number"
            selectedValue={productionOrderNum}
            selectOptions={[
              defaultProductionOrderNum,
              ...productionOrderNumbers
            ]}
            onChangeHandler={(value: OptionItem) =>
              setProductionOrderNum(value)
            }
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No PO found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select part family"
            selectedValue={partType}
            selectOptions={[defaultOperation, ...partTypeOptions]}
            onChangeHandler={(value: OptionItem) => setPartType(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No part family found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select part number"
            selectedValue={partName}
            selectOptions={[defaultPartName, ...partNameOptions]}
            onChangeHandler={(value: OptionItem) => setPartName(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No part number found"
          />
        </Stack>
      </Stack>
      <Box sx={{ padding: '16px' }}>
        <StyledTableContainer>
          {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
          {!isLoading && filteredRows.length === 0 && (
            <StyledEmptyHeader>
              There are no production orders
            </StyledEmptyHeader>
          )}
          {!isLoading && filteredRows.length > 0 && (
            <StyledTable
              pagination={false}
              columns={productionOrderColumns}
              data={filteredRows}
              hiddenColumns={['id', 'index']}
            />
          )}
        </StyledTableContainer>
      </Box>
      {openAddPartModal && (
        <AddProductionOrderModal
          closeHandler={() => setOpenAddPartModal(false)}
          isModalOpen={openAddPartModal}
          addProductionOrder={addProductionOrder}
          editProductionOrder={editProductionOrder}
          isEditMode={isEditMode}
          selectedProductionOrder={selectedProductionOrder}
        />
      )}
      {selectedProductionOrder && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following production order ${selectedProductionOrder.workOrderNum}`}
          buttonTitle="Delete"
          clickHandler={() => deletePart(selectedProductionOrder.workOrderNum)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  )
}

export default ProductionOrder
